import { makeStyles, fade } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    margin: 0,
  },
  Logo: {
    position: "relative",
    left: "20%",
    width: "100px",
    zIndex: -1,
  },
  toolBar: {
    display: "flex",
    justifyContent: "space-between",
  },
  type: {
    zIndex: 9999,
    display: "inline",
    overflow: "hidden",
    marginLeft: "100px",
    fontFamily: "Montserrat",
    fontSize: "20px",
    background: "linear-gradient(to right, #c9fb36 0%, #0faba6 100%)",
    textTransform: "uppercase",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  menuItem: {
    display: "inline-block",
    margin: `0 ${theme.spacing(2)}px`,
    "& $menuIcon": {},
  },
  menuItemIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  menuIcon: {
    color: theme.palette.common.white,
  },
  menuLeft: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default useStyles;
