/* eslint-disable import/no-anonymous-default-export */
import { put, call } from "redux-saga/effects";
import get from "lodash/get";
import api from "~/Services/Api";
import { StorageKeys } from "../../Configs/Storage";
import EntityActions from "../Actions/Entity";
import AccountActions from "../Actions/Account";
import { getStorage } from "~/Services/Helpers";
import { extractMessage } from "~/Services/Helpers/EntityResponseHandler";

export default {
  *get({ id, data }) {
    const token = getStorage(StorageKeys.token);
    try {
      if (id === "GenericDropdownMultiple") {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.GenericHandlers.get, data, {
          headers: { Authorization: token },
        });

        if (res.ok) {
          yield put(
            EntityActions.getSucceeded(id, {
              content: res.data.content,
              uri: data.uri,
              code: data.code,
            })
          );
        } else {
          yield put(
            EntityActions.getFailed(id, {
              code: data.code,
              message: extractMessage(res.data),
            })
          );
        }
      }
      if (id === "DynamicInput") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.GenericHandlers.get, data, {
            headers: { Authorization: token },
          });
          if (res.ok) {
            yield put(
              EntityActions.getSucceeded(id, {
                content: res.data.content,
                uri: data.uri,
                code: data.code,
                parentID: data.parentID,
              })
            );
          } else {
            yield put(
              EntityActions.getFailed(id, {
                code: data.code,
                message: extractMessage(res.data),
              })
            );
          }
        }
      }
      if (id === "BrowseBrands") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.brands.getAll, data, {
            headers: { Authorization: token },
          });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            yield put(EntityActions.getFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === "BrowseQuestions") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          let res;
          if (data.type === 1) {
            res = yield call(api.questions.getAllBySupplier, data, {
              headers: { Authorization: token },
            });
          } else {
            res = yield call(api.questions.getAll, data, {
              headers: { Authorization: token },
            });
          }
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            yield put(EntityActions.getFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === "BrowseAvailableTenders") {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.tenders.available.getAll, data, {
          headers: { Authorization: token },
        });
        if (res.ok) {
          yield put(EntityActions.getSucceeded(id, res.data));
        } else {
          yield put(EntityActions.getFailed(id, extractMessage(res.data)));
        }
      }
      if (id === "BrowseTenderQuotations") {
        const token = getStorage(StorageKeys.token);
        const res = yield call(
          api.tenders.quotations.getAll,
          {},
          { headers: { Authorization: token } }
        );

        if (res.ok) {
          yield put(EntityActions.getSucceeded(id, res.data.content));
        } else {
          yield put(EntityActions.getFailed(id, extractMessage(res.data)));
        }
      }
      if (id === "BrowseTenderOrders") {
        const token = getStorage(StorageKeys.token);
        const res = yield call(
          api.tenders.orders.getAll,
          {},
          { headers: { Authorization: token } }
        );

        if (res.ok) {
          yield put(EntityActions.getSucceeded(id, res.data.content));
        } else {
          yield put(EntityActions.getFailed(id, extractMessage(res.data)));
        }
      }
      if (id === "BrowseSentRequests") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.requests.sent.getAll, data, {
            headers: { Authorization: token },
          });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            yield put(EntityActions.getFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === "BrowseReceivedRequests") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.requests.received.getAll, data, {
            headers: { Authorization: token },
          });

          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            yield put(EntityActions.getFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === "ShowViewTender") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.tenders.available.getOne, data, {
            headers: { Authorization: token },
          });

          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data.content));
          } else {
            yield put(EntityActions.getFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === "ViewQuestion") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          let res;
          if (data.type === 1) {
            res = yield call(api.questions.getOneBySupplier, data, {
              headers: { Authorization: token },
            });
          } else {
            res = yield call(api.questions.getOne, data, {
              headers: { Authorization: token },
            });
          }

          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data.content));
          } else {
            yield put(EntityActions.getFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === "DivisionForm") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.divisions.getOne, data, {
            headers: { Authorization: token },
          });

          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data.content));
          } else {
            yield put(EntityActions.getFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === "GeneralTenderAction") {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.products.getAll, data, {
          headers: { Authorization: token },
        });

        if (res.ok) {
          yield put(EntityActions.getSucceeded(id, res.data));
        } else {
          yield put(EntityActions.getFailed(id, extractMessage(res.data)));
        }
      }
      if (id === "TeamForm") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.members.getOne, data, {
            headers: { Authorization: token },
          });

          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            yield put(EntityActions.getFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === "BrowseInquiries") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.inquiries.getAll, data, {
            headers: { Authorization: token },
          });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data.content));
          } else {
            yield put(EntityActions.getFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === "BrowseSupportBrandProducts") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.inquiries.getAll, data, {
            headers: { Authorization: token },
          });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data.content));
          } else {
            yield put(EntityActions.getFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === "ViewProfile") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.account.profile, data, {
            headers: { Authorization: token },
          });

          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            yield put(EntityActions.getFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === "InquiryAction") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.Quotation.getOne, data, {
            headers: { Authorization: token },
          });

          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            yield put(EntityActions.getFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === "SupportBrandProductForm") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.brands.getProduct, data, {
            headers: { Authorization: token },
          });

          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data.content));
          } else {
            yield put(EntityActions.getFailed(id, extractMessage(res.data)));
          }
        }
      }

      if (id === "BrandForm") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.brands.getOne, data, {
            headers: { Authorization: token },
          });

          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data.content));
          } else {
            yield put(EntityActions.getFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === "GenericDropdown") {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.GenericHandlers.get, data, {
          headers: { Authorization: token },
        });

        if (res.ok) {
          yield put(
            EntityActions.getSucceeded(id, {
              content: res.data.content,
              uri: data.uri,
              code: data.code,
            })
          );
        } else {
          const error = get(res, "data.message.en", " ");
          yield put(
            EntityActions.getFailed(id, {
              code: data.code,
              message: extractMessage(res.data),
            })
          );
        }
      }
      if (id === "ProfileForm") {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.account.profile, data, {
          headers: { Authorization: token },
        });
        if (res.ok) {
          yield put(EntityActions.getSucceeded(id, res.data.content));
        } else {
          yield put(EntityActions.getFailed(id, extractMessage(res.data)));
        }
      }
      if (id === "BrowseDivisions") {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.divisions.getAll, data, {
          headers: { Authorization: token },
        });
        if (res.ok) {
          yield put(EntityActions.getSucceeded(id, res.data));
        } else {
          yield put(EntityActions.getFailed(id, extractMessage(res.data)));
        }
      }
      if (id === "BrowseDistributors") {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.distributors.getAll, data, {
          headers: { Authorization: token },
        });
        if (res.ok) {
          yield put(EntityActions.getSucceeded(id, res.data));
        } else {
          yield put(EntityActions.getFailed(id, extractMessage(res.data)));
        }
      }
      if (id === "DistributorForm") {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.distributors.getOne, data, {
          headers: { Authorization: token },
        });

        if (res.ok) {
          yield put(EntityActions.getSucceeded(id, res.data.content));
        } else {
          yield put(EntityActions.getFailed(id, extractMessage(res.data)));
        }
      }
      if (id === "BrowseOrders") {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.orders.getAll, data, {
          headers: { Authorization: token },
        });

        if (res.ok) {
          yield put(EntityActions.getSucceeded(id, res.data));
        } else {
          yield put(EntityActions.getFailed(id, extractMessage(res.data)));
        }
      }

      if (id === "RequestAction") {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.requests.received.getOne, data, {
          headers: { Authorization: token },
        });

        if (res.ok) {
          yield put(EntityActions.getSucceeded(id, res.data));
        } else {
          yield put(EntityActions.getFailed(id, extractMessage(res.data)));
        }
      }
      if (id === "BrowseTeams") {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.members.getAll, data, {
          headers: { Authorization: token },
        });

        if (res.ok) {
          yield put(EntityActions.getSucceeded(id, res.data));
        } else {
          yield put(EntityActions.getFailed(id, extractMessage(res.data)));
        }
      }
      if (id === "BrowseCatalogs") {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.catalogs.getAll, data, {
          headers: { Authorization: token },
        });
        if (res.ok) {
          yield put(EntityActions.getSucceeded(id, res.data.content));
        } else {
          yield put(EntityActions.getFailed(id, extractMessage(res.data)));
        }
      }
      if (id === "productCatalogs") {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.productCatalogs.getAll, data, {
          headers: { Authorization: token },
        });
        if (res.ok) {
          yield put(EntityActions.getSucceeded(id, res.data.content));
        } else {
          yield put(EntityActions.getFailed(id, extractMessage(res.data)));
        }
      }
      if (id === "ProductCatalogForm") {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.productCatalogs.getOne, data, {
          headers: { Authorization: token },
        });
        if (res.ok) {
          yield put(EntityActions.getSucceeded(id, res.data.content));
        } else {
          const error = get(res, "data.message.en", " ");
          yield put(EntityActions.getFailed(id, error));
        }
      }
      if (id === "CatalogForm") {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.catalogs.getOne, data, {
          headers: { Authorization: token },
        });
        if (res.ok) {
          yield put(EntityActions.getSucceeded(id, res.data.content));
        } else {
          const error = get(res, "data.message.en", " ");
          yield put(EntityActions.getFailed(id, error));
        }
      }
    } catch (errors) {
      yield put(EntityActions.postFailed(id, "errorConnection"));
    }
  },
  *post({ id, data }) {
    try {
      if (id === "login") {
        const res = yield call(api.account.login, data);
        if (res.ok) {
          const { token } = res.data.content;
          yield put(AccountActions.setToken(token));
          yield put(EntityActions.postSucceeded(id, res.data));
        } else {
          yield put(EntityActions.postFailed(id, extractMessage(res.data)));
        }
      }
      if (id === "ProfileForm") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.media.changeMediaState, data, {
            headers: { Authorization: token },
          });
          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data));
          } else {
            yield put(EntityActions.postFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === "QuestionForm") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.questions.add, data, {
            headers: { Authorization: token },
          });

          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data));
          } else {
            yield put(EntityActions.postFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === "AnswerForm") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.answers.add, data, {
            headers: { Authorization: token },
          });

          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data));
          } else {
            yield put(EntityActions.postFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === "TeamForm") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.members.add, data, {
            headers: { Authorization: token },
          });

          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data));
          } else {
            yield put(EntityActions.postFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === "OrderAction") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          let res;
          if (data.type === "bundle") {
            res = yield call(api.orders.changeBundleStatus, data, {
              headers: { Authorization: token },
            });
          } else {
            res = yield call(api.orders.changeStatus, data, {
              headers: { Authorization: token },
            });
          }

          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data));
          } else {
            yield put(EntityActions.postFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === "TenderOrderAction") {
        const token = getStorage(StorageKeys.token);
        if (token) {

          const res = yield call(api.tenders.orders.changeStatus, data, {
            headers: { Authorization: token },
          });

          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data));
          } else {
            yield put(EntityActions.postFailed(id, extractMessage(res.data)));
          }
        }
      }

      if (id === "DistributorForm") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.distributors.add, data, {
            headers: { Authorization: token },
          });

          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data));
          } else {
            yield put(EntityActions.postFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === "GeneralTenderAction") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.tenders.available.send, data, {
            headers: { Authorization: token },
          });

          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data));
          } else {
            yield put(EntityActions.postFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === "AvailableTenderAction") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.tenders.available.send, data, {
            headers: { Authorization: token },
          });

          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data));
          } else {
            yield put(EntityActions.postFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === "ProductRequest") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.products.addProductRequest, data, {
            headers: { Authorization: token },
          });

          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data));
          } else {
            yield put(EntityActions.postFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === "BrowseTenderQuotations") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.tenders.available.send, data, {
            headers: { Authorization: token },
          });

          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data));
          } else {
            yield put(EntityActions.postFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === "RequestAction") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.requests.received.changeStatus, data, {
            headers: { Authorization: token },
          });

          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data));
            window.location.reload();
          } else {
            yield put(EntityActions.postFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === "InquiryAction") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          let res;
          if (data.reqType === "sendBundleQuotation") {
            res = yield call(api.Quotation.sendBundleQuotation, data, {
              headers: { Authorization: token },
            });
          } else {
            res = yield call(api.Quotation.send, data, {
              headers: { Authorization: token },
            });
          }

          if (res.ok) {
            yield put(
              EntityActions.postSucceeded(id, {
                data: res.data,
                reqType: data.reqType,
              })
            );
          } else {
            yield put(EntityActions.postFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === "DivisionForm") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.divisions.add, data, {
            headers: { Authorization: token },
          });

          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data));
          } else {
            yield put(EntityActions.postFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === "BrandForm") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.brands.add, data, {
            headers: { Authorization: token },
          });

          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data));
          } else {
            yield put(EntityActions.postFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === "ImageCrop") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.media.changeMediaState, data, {
            headers: { Authorization: token },
          });
          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data));
          } else {
            yield put(EntityActions.postFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === "CatalogForm") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.catalogs.add, data, {
            headers: { Authorization: token },
          });
          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data));
          } else {
            yield put(EntityActions.postFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === "ProductCatalogForm") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          let res;
          if (data.type == "createProductCatalog") {
            res = yield call(api.productCatalogs.add, data.values, {
              headers: { Authorization: token },
            });
          }
          if (res.ok) {
            yield put(
              EntityActions.postSucceeded(id, {
                data: res.data,
                type: data.type,
              })
            );
          } else {
            yield put(EntityActions.postFailed(id, extractMessage(res.data)));
          }
        }
      }
    } catch (errors) {
      yield put(EntityActions.postFailed(id, "errorConnection"));
    }
  },
  *put({ id, data }) {
    try {
      if (id === "ProductCatalogForm") {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.productCatalogs.update, data, {
          headers: { Authorization: token },
        });
        if (res.ok) {
          yield put(EntityActions.patchSucceeded(id, res.data));
        } else {
          yield put(EntityActions.patchFailed(id, extractMessage(res.data)));
        }
      }
      if (id === "CatalogForm") {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.catalogs.update, data, {
          headers: { Authorization: token },
        });
        if (res.ok) {
          yield put(EntityActions.patchSucceeded(id, res.data));
        } else {
          yield put(EntityActions.patchFailed(id, extractMessage(res.data)));
        }
      }
      if (id === "ProfileForm") {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.account.updateProfile, data, {
          headers: { Authorization: token },
        });
        if (res.ok) {
          yield put(EntityActions.patchSucceeded(id, res.data));
        } else {
          yield put(EntityActions.patchFailed(id, extractMessage(res.data)));
        }
      }
    } catch (errors) {
      console.log("errors", errors);
      yield put(EntityActions.patchFailed(id, "errorConnection"));
    }
  },
  *patch({ id, data }) {
    try {
      if (id === "ProfileForm") {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.profile.update, data, {
          headers: { Authorization: token },
        });
        if (res.ok) {
          yield put(EntityActions.patchSucceeded(id, res.data));
        } else {
          yield put(EntityActions.patchFailed(id, extractMessage(res.data)));
        }
      }
      if (id === "DistributorForm") {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.distributors.update, data, {
          headers: { Authorization: token },
        });

        if (res.ok) {
          yield put(EntityActions.putSucceeded(id, res.data));
        } else {
          yield put(EntityActions.putFailed(id, extractMessage(res.data)));
        }
      }
      if (id === "TeamForm") {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.members.update, data, {
          headers: { Authorization: token },
        });

        if (res.ok) {
          yield put(EntityActions.putSucceeded(id, res.data));
        } else {
          yield put(EntityActions.putFailed(id, extractMessage(res.data)));
        }
      }
      if (id === "QuestionForm") {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.questions.update, data, {
          headers: { Authorization: token },
        });

        if (res.ok) {
          yield put(EntityActions.putSucceeded(id, res.data));
        } else {
          yield put(EntityActions.putFailed(id, extractMessage(res.data)));
        }
      }
      if (id === "AnswerForm") {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.answers.update, data, {
          headers: { Authorization: token },
        });

        if (res.ok) {
          yield put(EntityActions.putSucceeded(id, res.data));
        } else {
          yield put(EntityActions.putFailed(id, extractMessage(res.data)));
        }
      }
      if (id === "DivisionForm") {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.divisions.update, data, {
          headers: { Authorization: token },
        });

        if (res.ok) {
          yield put(EntityActions.patchSucceeded(id, res.data));
        } else {
          yield put(EntityActions.patchFailed(id, extractMessage(res.data)));
        }
      }
      if (id === "BrandForm") {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.brands.update, data, {
          headers: { Authorization: token },
        });

        if (res.ok) {
          yield put(EntityActions.patchSucceeded(id, res.data));
        } else {
          yield put(EntityActions.patchFailed(id, extractMessage(res.data)));
        }
      }
      if (id === "SupportBrandProductForm") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.brands.updateProduct, data, {
            headers: { Authorization: token },
          });
          if (res.ok) {
            yield put(EntityActions.patchSucceeded(id, res.data));
          } else {
            yield put(EntityActions.patchFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === "ProductCatalogForm") {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.productCatalogs.update, data, {
          headers: { Authorization: token },
        });
        if (res.ok) {
          yield put(EntityActions.patchSucceeded(id, res.data));
        } else {
          yield put(EntityActions.patchFailed(id, extractMessage(res.data)));
        }
      }
      if (id === "CatalogForm") {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.catalogs.update, data, {
          headers: { Authorization: token },
        });
        if (res.ok) {
          yield put(EntityActions.patchSucceeded(id, res.data));
        } else {
          yield put(EntityActions.patchFailed(id, extractMessage(res.data)));
        }
      }
    } catch (errors) {
      yield put(EntityActions.patchFailed(id, "errorConnection"));
    }
  },
  *delete({ id, data }) {
    try {
      if (id === "BrowseCatalogs") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.catalogs.delete, data, {
            headers: { Authorization: token },
          });

          if (res.ok) {
            yield put(EntityActions.deleteSucceeded(id, {}));
          } else {
            const error = get(res, "data.message", "Connection Error");
            yield put(EntityActions.deleteFailed(id, error));
          }
        }
      }
      if (id === "ViewQuestion") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.answers.delete, data, {
            headers: { Authorization: token },
          });

          if (res.ok) {
            yield put(EntityActions.deleteSucceeded(id, {}));
          } else {
            const error = get(res, "data.message", "Connection Error");
            yield put(EntityActions.deleteFailed(id, error));
          }
        }
      }
      if (id === "BrowseQuestions") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          let res;
          if (data.type === 1) {
            res = yield call(api.questions.deleteBySupplier, data, {
              headers: { Authorization: token },
            });
          } else {
            res = yield call(api.questions.delete, data, {
              headers: { Authorization: token },
            });
          }

          if (res.ok) {
            yield put(EntityActions.deleteSucceeded(id, {}));
          } else {
            const error = get(res, "data.message", "Connection Error");
            yield put(EntityActions.deleteFailed(id, error));
          }
        }
      }
      if (id === "productCatalogs") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.productCatalogs.delete, data, {
            headers: { Authorization: token },
          });
          if (res.ok) {
            yield put(EntityActions.deleteSucceeded(id, {}));
          } else {
            const error = get(res, "data.message", "Connection Error");
            yield put(EntityActions.deleteFailed(id, error));
          }
        }
      }

      if (id === "BrowseDivisions") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.divisions.delete, data, {
            headers: { Authorization: token },
          });

          if (res.ok) {
            yield put(EntityActions.deleteSucceeded(id, res.data));
          } else {
            yield put(EntityActions.deleteFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === "BrowseBrands") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.brands.delete, data, {
            headers: { Authorization: token },
          });
          if (res.ok) {
            yield put(EntityActions.deleteSucceeded(id, res.data));
          } else {
            yield put(EntityActions.deleteFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === "BrowseDistributors") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.distributors.delete, data, {
            headers: { Authorization: token },
          });

          if (res.ok) {
            yield put(EntityActions.deleteSucceeded(id, res.data));
          } else {
            yield put(EntityActions.deleteFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === "BrowseTeams") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.members.delete, data, {
            headers: { Authorization: token },
          });
          if (res.ok) {
            yield put(EntityActions.deleteSucceeded(id, res.data));
          } else {
            yield put(EntityActions.deleteFailed(id, extractMessage(res.data)));
          }
        }
      }
    } catch (errors) {
      yield put(EntityActions.patchFailed(id, "errorConnection"));
    }
  },
};
