import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core';
import { useAppContext } from './AppContextProvider';

export const palette = {
  primary: { main: '#014758' },
  secondary: { main: '#18b2aa', dark: '#067e7a', light: '#c9fb36' },
  danger: { main: '#D90368' },
  light: { main: '#F1E9DA' },
  dark: { main: '#0F0F0F' },
};

const ltrTheme = createMuiTheme({
  direction: 'ltr',
  palette,
});

const rtlTheme = createMuiTheme({
  direction: 'rtl',
  palette,
});

export function ThemeContextProvider({ children }) {
  const { appContextObj } = useAppContext();
  const theme = appContextObj.direction === 'rtl' ? rtlTheme : ltrTheme;
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
