import { Fullscreen } from "@material-ui/icons";
import Api from "../../Configs/Api";

export * from "./AssetsHelpers";
export * from "./LocalStorageHelpers";
export * from "./RouterHelpers";
export * from "./PermissionsHelper";
export * from "./FormDataDuplicationChecker";
export * from "./BrowserHelper";
export * from "./EntityResponseHandler";
export * from "./Parsers";

export const globalLinkValidation =
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;

export const types = [
  { name: "Supplier", id: 1 },
  { name: "Distributor", id: 2 },
  { name: "Normal", id: 3 },
  { name: "Member", id: 4 },
];

export const handleChange = (fileObject) => {
  const file = fileObject;
  let reader = new FileReader();
  if (file) {
    let formData = new FormData();
    formData.append("file", file);
    return formData;
  }
};

export function UpperCaseFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getTomorrowDate = () => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  return tomorrow;
};

export function FullscreenHandler(ScreenElem) {
  const elem = ScreenElem;

  this.openFullScreen = () => {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari & Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
    }
  };

  this.closeFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  };
}

export const DataToQueryParams = (data) =>
  Object.keys(data).reduce((acc, key, index) =>
    index === 1
      ? `?${acc}=${data[acc]}&${key}=${data[key]}`
      : `${acc}&${key}=${data[key]}`
  );

export const onlyOneOfProps = (fields) => (props, propName, componentName) => {
  let isFieldProvided = false;
  let errorObj = new Error(
    `${componentName}.props ((${propName}))only one of the following props should be provided [${Object.keys(
      fields
    )}] `
  );

  Object.keys(fields).forEach((field) => {
    if (!!props[field]) {
      if (isFieldProvided) return errorObj;
      isFieldProvided = true;
    }
  });

  if (!isFieldProvided) return errorObj;
};

// compare two object that have the same structure and check if they are equals
export const compareObjects = (firstVal, secondVal) =>
  JSON.stringify(firstVal) === JSON.stringify(secondVal);

export const isEmpty = (value) =>
  value === undefined ||
  value === null ||
  (typeof value === "object" && Object.keys(value).length === 0) ||
  (typeof value === "string" && value.trim().length === 0);

export const fixedStorageLink = Api.storageUrl;

export const getMedia = (path) =>
  !isEmpty(path) ? fixedStorageLink + path : "";
