import { Breadcrumbs, Typography, Button } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { UpperCaseFirst } from "~/Services/Helpers";
import useStyles from "./styles";

const SubHeader = () => {
  const classes = useStyles();
  const history = useHistory();
  const locationArr = history.location.pathname.split("/");
  const forward = (isLast, index) => {
    if (!isLast) history.go(-(index - 1));
  };

  return (
    <div style={{ display: "flex" }}>
      {history.location.pathname != "/" && (
        <Button
          startIcon={<ArrowBack />}
          onClick={() => history.goBack()}
          style={{ marginRight: 3 }}
        />
      )}

      <Breadcrumbs maxItems={4} aria-label="breadcrumb">
        <Typography variant="h5">{UpperCaseFirst(locationArr[1])}</Typography>

        {locationArr.length > 2 &&
          locationArr.map((path, index) => {
            if (index > 1) {
              const isLast = path === locationArr[locationArr.length - 1];
              return (
                <p
                  style={{
                    textDecoration: !isLast ? "underline" : "none",
                    cursor: !isLast ? "pointer" : "auto",
                  }}
                  onClick={() => forward(isLast, index)}
                  color="inherit"
                  key={index}
                  className={classes.breadCrumb}
                >
                  {UpperCaseFirst(path)}
                </p>
              );
            }
          })}
      </Breadcrumbs>
    </div>
  );
};

export default SubHeader;
