import AttachFileIcon from "@material-ui/icons/AttachFile";
import { fixedStorageLink, toAbsoluteUrl } from "./";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import FacebookIcon from "@material-ui/icons/Facebook";
import { Chip, Icon, IconButton } from "@material-ui/core";
import { isEmpty } from "~/Services/Helpers";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import LazyLoad from "react-lazyload";

const onError = (ev) => {
  ev.target.src = toAbsoluteUrl("/img-placeholder.png");
};

export const evaluateLink = (value) =>
  !isEmpty(value) ? (
    <a target="_blank" href={value}>
      <Icon color="primary">link</Icon>
    </a>
  ) : (
    <></>
  );

export const evaluateBoolean = (value) =>
  value ? <Chip label={"Yes"} /> : <Chip label={"No"} />;

export const evaluateLicence = (value) =>
  value.includes("Not Upload Licence") ? (
    <CloseIcon variant="outline" color={"error"}></CloseIcon>
  ) : (
    <CheckIcon variant="outline" color={"success"}></CheckIcon>
  );

export const evaluateDate = (value) => {
  const date = new Date(value);
  console.log(date,date.getMonth())
  if (!isEmpty(value))
    return `${date.getFullYear()}-${date.getMonth() +1 }-${date.getDate()}`;
  else return <></>;
};

export const evaluateFacebook = (value) =>
  !isEmpty(value) ? (
    <a target="_blank" href={value}>
      <IconButton component="span">
        <FacebookIcon />
      </IconButton>
    </a>
  ) : (
    <></>
  );

export const evaluateLinkedIn = (value) =>
  !isEmpty(value) ? (
    <a target="_blank" href={value}>
      <IconButton component="span">
        <LinkedInIcon />
      </IconButton>
    </a>
  ) : (
    <></>
  );

export const evaluateFile = (value) =>
  !isEmpty(value) ? (
    <a style={{ width: "70px", fontSize: "1rem" }} target="_blank" href={value}>
      <AttachFileIcon color="primary" />
    </a>
  ) : (
    <></>
  );

export const evaluateFiles = (array) => {
  if (isEmpty(array) || array === []) {
    return <></>;
  }
  const list = [];
  array.map((info) => {
    list.push(evaluateFile(info));
  });
  return list;
};
export const evaluateImage = (value, isUser, isShow) => {
  return (
    <LazyLoad>
      <img
        style={isShow ? styles.imageView : styles.imageBrowse}
        src={
          isEmpty(value)
            ? toAbsoluteUrl("/img-placeholder.png")
            : fixedStorageLink + value
        }
        onError={onError}
      />
    </LazyLoad>
  );
};
export const evaluateChips = (array) => {
  if (isEmpty(array) || array === []) {
    return <></>;
  }
  let chipsArray = [];
  array.map((info) => {
    chipsArray.push(<Chip label={info["distributor"].name} />);
  });
  return chipsArray;
};
export const evaluateChip = (value, styles) =>
  !isEmpty(value) ? (
    <Chip style={!styles ? {} : styles} label={value} />
  ) : (
    <></>
  );

const styles = {
  imageBrowse: {
    width: "90px",
    height: "90px",
    marginRight: "10px",
  },
  imageView: {
    margin: "0px auto",
    display: "block",
    textAlign: "center",
    height: "150px",
    width: "150px",
  },
};
