const Seconds = (n) => n * 1000;

const baseURL = !!process.env.PUBLIC_URL
    ? process.env.PUBLIC_URL
    : "http://127.0.0.1:8000";

export const getURL = (path) =>
    !!process.env.REACT_APP_BACKEND_BASE_URL
        ? `${process.env.REACT_APP_BACKEND_BASE_URL}/${path}/`
        : `http://127.0.0.1:8000/${path}/`;

export default {
  baseURL,
  apiUrl: getURL("api"),
  timeout: Seconds(100),
  clientToken: "SECRET_TOKEN",
  version: "1.0.0",
  email: "support@membs.com",
  phone: "+963932415979",
  storageUrl: 'http://127.0.0.1:8000/storage/',
  multipleMediaFileUpload: "admin/file-upload",
  multipleMediaImageUpload: "admin/media-upload",
};
