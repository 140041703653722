
import { lazy } from 'react';

const EmptyPage = () => (
  <div style={{ position: 'absolute', top: '50px', left: '44%' }}>
    <h4> Page not build yet </h4>
  </div>
);

export const privateRoutes = [
  { permission: ['manage supplier inquiry', 'manage distributor inquiry'], path: '/administration/manage/inquiries', component: lazy(() => import('../Screens/Inquiries/Browse')), exact: true },
  { permission: ['manage supplier inquiry', 'manage distributor inquiry'], path: '/administration/manage/inquiries/:id', component: lazy(() => import('../Screens/Inquiries/Edit-View')), exact: true },
  { permission: ['manage distributor order', 'manage supplier order'], path: '/administration/manage/orders', component: lazy(() => import('../Screens/Orders')), exact: true },
  { permission: ['request product'], path: '/administration/manage/products', component: lazy(() => import('../Screens/Products')), exact: true },
  { permission: 'request product', path: '/administration/manage/products/add', component: lazy(() => import('../Screens/Products/Partials/ProductForm')), exact: true },
  { permission: 'manage supplier division', path: '/administration/manage/divisions', component: lazy(() => import('../Screens/Divisions/Browse')), exact: true },
  { permission: 'manage supplier division', path: '/administration/manage/divisions/add', component: lazy(() => import('../Screens/Divisions/Add')), exact: true },
  { permission: 'manage supplier division', path: '/administration/manage/divisions/:id', component: lazy(() => import('../Screens/Divisions/Edit-View')), exact: true },
  { permission: 'manage distributor', path: '/administration/manage/distributors', component: lazy(() => import('../Screens/Distributors/Browse')), exact: true },
  { permission: 'manage distributor', path: '/administration/manage/distributors/add', component: lazy(() => import('../Screens/Distributors/Add')), exact: true },
  { permission: 'manage distributor', path: '/administration/manage/distributors/:id', component: lazy(() => import('../Screens/Distributors/Edit-View')), exact: true },
  { permission: 'manage distributor team', path: '/administration/manage/teams', component: lazy(() => import('../Screens/Teams/Browse')), exact: true },
  { permission: 'manage distributor team', path: '/administration/manage/teams/add', component: lazy(() => import('../Screens/Teams/Add')), exact: true },
  { permission: 'manage distributor team', path: '/administration/manage/teams/:id', component: lazy(() => import('../Screens/Teams/Edit-View')), exact: true },
  { permission: 'manage distributor brand', path: '/administration/brands/supported-brands', component: lazy(() => import('../Screens/SupportedBrands/Browse')), exact: true },
  { permission: 'manage distributor brand', path: '/administration/brands/supported-brands/add', component: lazy(() => import('../Screens/SupportedBrands/Add')), exact: true },
  { permission: 'manage distributor brand', path: '/administration/brands/supported-brands/:id', component: lazy(() => import('../Screens/SupportedBrands/Edit-View')), exact: true },
  { permission: 'manage distributor brand', path: '/administration/brands/supported-brands/:id/products', component: lazy(() => import('../Screens/SupportedBrands/SupportBrandProduct/Browse')), exact: true },
  { permission: 'manage distributor brand', path: '/administration/brands/supported-brands/:id/products/:supportBrandId', component: lazy(() => import('../Screens/SupportedBrands/SupportBrandProduct/Edit-View')), exact: true },
  { permission: ['distributor stats', 'supplier stats'], path: '/administration/stats/default', component: lazy(() => import('../Screens/Stats/Browse')), exact: true },
  { permission: 'manage distributor catalog', path: '/administration/shop/product-catalog', component: lazy(() => import('../Screens/Shop/Catalogs/Browse')), exact: true },
  { permission: ['manage supplier request', 'manage distributor request'], path: '/administration/manage/requests', component: lazy(() => import('../Screens/PortalRequests')), exact: true },
  { permission: ['manage distributor tender'], path: '/administration/tenders/available-tenders', component: lazy(() => import('../Screens/Tenders/AvailableTenders/Browse')), exact: true },
  { permission: ['manage distributor tender'], path: '/administration/tenders/tender-quotations/:id', component: lazy(() => import('../Screens/Tenders/AvailableTenders/ShowView')), exact: true },
  { permission: ['manage distributor tender'], path: '/administration/tenders/available-tenders/:id', component: lazy(() => import('../Screens/Tenders/AvailableTenders/ShowView')), exact: true },
  { permission: ['manage distributor tender'], path: '/administration/tenders/tender-orders', component: lazy(() => import('../Screens/Tenders/TendersOrders/Browse')), exact: true },
  { permission: ['manage distributor tender'], path: '/administration/tenders/tender-quotations', component: lazy(() => import('../Screens/Tenders/TenderQuatations/Browse')), exact: true },

  { permission: ['manage distributor question', 'manage supplier question'], path: '/administration/questions/add', component: lazy(() => import('../Screens/Questions/Add')), exact: true },
  { permission: ['manage distributor question', 'manage supplier question'], path: '/administration/questions', component: lazy(() => import('../Screens/Questions/Browse')), exact: true },
  { permission: ['manage distributor question', 'manage supplier question'], path: '/administration/questions/:id', component: lazy(() => import('../Screens/Questions/Edit-View')), exact: true },


  { permission: ['distributor stats', 'supplier stats'], path: '/', component: lazy(() => import('../Screens/Profile/Edit-View')), exact: true },

  { path: "/*", component: EmptyPage, exact: true },
];


export const authRoutes = [
  { path: '/login', component: lazy(() => import('../Screens/Auth/Login')) },
];

export const publicRoutes = [];
