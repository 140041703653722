import { InputBase } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { privateRoutes } from "~/Routes/RouteList";
import { UpperCaseFirst } from "~/Services/Helpers";
import useStyles from "./styles";
import { getUserPermissions, userHavePermission } from "~/Services/Helpers";
import { useUserContext } from "~/Services/Providers";

function SearchBar(props) {
  const classes = useStyles();
  const history = useHistory();
  const normalizedData = [];
  const { userContextObj } = useUserContext();

  useEffect(() => {
    const unWantedPaths = ["add", "edit", "id", "*", "dashboard"];
    const permissions = userContextObj?.role
      ? getUserPermissions(userContextObj.role)
      : [];
    privateRoutes.map((route) => {
      if (
        !unWantedPaths.some((unWantedPath) =>
          route.path.includes(unWantedPath)
        ) &&
        userHavePermission(permissions, ...[route.permission])
      ) {
        normalizedData.push({
          title: route.path.split("/")[3].replace(/-/g, " "),
          path: route.path,
        });
      }
    });
  });

  return (
    <>
      <div className={classes.searchIcon}>
        <Search />
      </div>

      <Autocomplete
        freeSolo
        id="free-solo-2-demo"
        disableClearable
        autoFocus
        options={normalizedData}
        getOptionLabel={(option) => option.path}
        renderOption={(option) => UpperCaseFirst(option.title)}
        onInputChange={(e, value, reason) => history.push(value)}
        renderInput={(params) => (
          <InputBase
            {...params}
            ref={params.InputProps.ref}
            inputProps={params.inputProps}
            autoFocus
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
          />
        )}
      />
    </>
  );
}

export default SearchBar;
