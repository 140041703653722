import React from 'react'
import { useHistory } from 'react-router-dom'
import { MenuTile } from '../../'
import useStyles from './styles'
import { useUserContext } from "~/Services/Providers";
import { getUserPermissions, userHavePermission } from "~/Services/Helpers";
import { getActivePermitedPath } from '~/Services/Helpers/PermissionsHelper';

const MainMenu = ({ data, setActive, handleDrawerToggle, activeNav }) => {
    const history = useHistory();
    const classes = useStyles();
    const { userContextObj } = useUserContext();
    const permissions = userContextObj?.role ? getUserPermissions(userContextObj.role) : [];
    const Menu = () => (
        <div>
            {
                Object.keys(data).map((key, index) => {
                    const activePath = getActivePermitedPath(permissions,data[key]);
                    return userHavePermission(permissions, ...data[key].permissions) ?
                        <MenuTile
                            key={index}
                            element={data[key]}
                            icon={data[key].icon}
                            active={activeNav.activeTile === key}
                            handleClick={() => {
                                if (data[key]?.sections?.length > 0) handleDrawerToggle(true);
                                else handleDrawerToggle(false);
                                setActive({ activeTile: key, path: activePath })
                                history.push(activePath)
                            }}
                        /> : <></>
                })

            }
        </div>
    );

    return <Menu />
}

export default MainMenu;