import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { StorageKeys } from '~/Configs/Storage';
import { getStorage } from '~/Services/Helpers';
import { getUserPermissions, userHavePermission, isEmpty } from "~/Services/Helpers";
import { useUserContext } from "~/Services/Providers";
import EmptyPage from '~/Components/UI/EmptyPage';

function PrivateRoute(props) {
    const { userContextObj } = useUserContext();
    const permissions = userContextObj?.role ? getUserPermissions(userContextObj.role) : [];
    let ItemPermissions = Array.isArray(props.permission) ? props.permission : [props.permission];

    return (
        getStorage(StorageKeys.token)
            ? userHavePermission(permissions, ...ItemPermissions)
                ? <Route {...props} />
                : <Route path="/*" component={EmptyPage} exact />
            : <Redirect to="/login" exact />
    )
}


export default PrivateRoute

