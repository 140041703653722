import ApiConfigs from "../../Configs/Api";
import axios from "axios";
import { getStorage } from "~/Services/Helpers";
import { StorageKeys } from "../../Configs/Storage";
import OneSignal from 'react-onesignal';

let axiosInstance = axios.create({
    baseURL: ApiConfigs.apiUrl,
    timeout: ApiConfigs.timeout,
});

const sendRequest = (config, successAction, errorAction) => {
    let { method, url, data, params } = config

    return axiosInstance({
        method,
        url,
        data,
        params,
        headers: {
            Accept: `application/json;v=${ApiConfigs.version}`,
            "Content-Type": "application/json",
            Authorization: getStorage(StorageKeys.token)
        },
    })
        .then((res) => successAction(res))
        .catch((err) => errorAction(err));
};

export const getAllNotification = (data, successAction, errorAction = (error) => console.log('error', "Some Thing Is Wrong")) => {
    let config = {
        method: 'GET',
        url: `/portal/notifications`,
        data,
        params: {
            page: data.page,
            limit: data.limit,
            no_pagination: data.no_pagination

        }
    }
    sendRequest(config,
        (result) => {
            successAction(result);
        }, (error) => {
            errorAction(error);
        })
};
export const makeAsRead = (id, successAction, errorAction = (error) => console.log('error', "Some Thing Is Wrong")) => {
    let config = {
        method: 'PUT',
        url: `/portal/mark-notifications-as-read`,
        data: {
            "notifications": [id]
        }
    }
    sendRequest(config,
        (result) => {
            successAction(result);
        }, (error) => {
            errorAction(error);
        })
};


export const getAllNotificationCount = (data, successAction, errorAction = (error) => console.log('error', "Some Thing Is Wrong")) => {
    let config = {
        method: 'GET',
        url: `/portal/notifications-count`,
        data
    }
    sendRequest(config,
        (result) => {
            successAction(result);
        }, (error) => {
            errorAction(error);
        })
};


export const oneSignalInit = ({ appId, subdomainName, userId, getAllNotificationsCount }) => {
    OneSignal.init({
        appId: appId,
        subdomainName:
            subdomainName,
        autoRegister: true,
        promptOptions: {
            actionMessage: "We'd like to show you notifications for the latest news.",
            acceptButtonText: "ALLOW",
            cancelButtonText: "NO THANKS"
        }
    }).then(() => {
        OneSignal.on('notificationDisplay', function (event) {
            getAllNotificationsCount();
        });

        OneSignal.on('subscriptionChange', function (isSubscribed) {
            OneSignal.sendTag("userId", userId);
        });

        OneSignal.isPushNotificationsEnabled().then(function (isEnabled) {
            if (isEnabled) {
                OneSignal.sendTag("userId", userId);

            } else {
                OneSignal.showHttpPrompt();
            }
        });
    })
}