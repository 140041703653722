import axios from "axios";
import { create } from "apisauce";
import ApiConfigs from "../Configs/Api";

const axiosInstance = axios.create({
  baseURL: ApiConfigs.apiUrl,
  timeout: ApiConfigs.timeout,
  headers: {
    Accept: `application/json;v=${ApiConfigs.version}`,
    "Content-Type": "application/json",
  },
});

const Api = create({ axiosInstance: axiosInstance });
const CancelToken = axios.CancelToken;
const requestsSourceCancellation = CancelToken.source();

const endpoints = {
  account: {
    login: (data, headers) =>
      Api.post("portal/login", data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    register: (data, headers) =>
      Api.post("auth/signUp", data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    profile: (data, headers) =>
      Api.get("portal/profile", data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    updateProfile: (data, headers) =>
      Api.put("portal/profile", data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    updateProfilePassword: (data, headers) =>
      Api.put("portal/profile/update-password", data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    resetPassword: (data, headers) =>
      Api.put(
        `Account/ChangePassword?oldPassword=${data.oldPassword}&Password=${data.password}`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
  },
  media: {
    changeMediaState: (data, headers) =>
      Api.post(`media-upload-delete`, data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
  },

  inquiries: {
    getAll: (data, headers) =>
      Api.get(
        `portal/${data.type}?page=${data.page}&limit=${data.limit}`,
        {},
        {
          ...headers,
          cancelToken: requestsSourceCancellation.token,
        }
      ),
  },
  Quotation: {
    send: (data, headers) => {
      return Api.post(
        `portal/distributor/quotation/${data.id}/send-quotation`,
        data.data,
        {
          ...headers,
          cancelToken: requestsSourceCancellation.token,
        }
      );
    },
    sendBundleQuotation: (data, headers) => {
      return Api.post(
        `portal/distributor/bundle-quotation/${data.id}/send-quotation`,
        data.data,
        {
          ...headers,
          cancelToken: requestsSourceCancellation.token,
        }
      );
    },
    getOne: (data, headers) =>
      Api.get(
        `portal/${data.type}/${data.id}/show`,
        {},
        {
          ...headers,
          cancelToken: requestsSourceCancellation.token,
        }
      ),
    getOneQuotation: (data, headers) =>
      Api.get(
        `portal/quotation/${data.id}`,
        {},
        {
          ...headers,
          cancelToken: requestsSourceCancellation.token,
        }
      ),
    getOneBundleQuotation: (data, headers) =>
      Api.get(
        `portal/bundle-quotation/${data.id}`,
        {},
        {
          ...headers,
          cancelToken: requestsSourceCancellation.token,
        }
      ),
  },
  address: {
    getAll: (data, headers) => {
      return Api.get(
        `portal/address`,
        {},
        {
          ...headers,
          cancelToken: requestsSourceCancellation.token,
        }
      );
    },
    getOne: (data, headers) => {
      return Api.get(
        `portal/address/${data.id}`,
        {},
        {
          ...headers,
          cancelToken: requestsSourceCancellation.token,
        }
      );
    },
    add: (data, headers) =>
      Api.post(`portal/address`, data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    update: (data, headers) => {
      return Api.put(`portal/address/${data.id}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      });
    },
    delete: (data, headers) => {
      return Api.delete(`portal/address`, data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      });
    },
  },
  members: {
    getAll: (data, headers) => {
      return Api.get(
        `portal/distributor/get-members`,
        {},
        {
          ...headers,
          cancelToken: requestsSourceCancellation.token,
        }
      );
    },
    getOne: (data, headers) => {
      return Api.get(
        `portal/distributor/get-members/${data}`,
        {},
        {
          ...headers,
          cancelToken: requestsSourceCancellation.token,
        }
      );
    },
    add: (data, headers) =>
      Api.post(`portal/distributor/add-member`, data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    update: (data, headers) => {
      return Api.patch(`portal/distributor/assign-member-permissions`, data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      });
    },
    delete: (data, headers) => {
      return Api.patch(`portal/distributor/remove-members`, data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      });
    },
  },
  dynamicComponent: {
    get: (data, headers) =>
      Api.get(
        `${data.uri}`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
  },
  brands: {
    getAll: (data, headers) =>
      Api.get(
        `portal/distributor/distributor-brands?page=${data.page}&limit=${data.limit}`,
        {},
        {
          ...headers,
          cancelToken: requestsSourceCancellation.token,
        }
      ),
    getOne: (data, headers) => {
      return Api.get(
        `portal/distributor/distributor-brands/${data}`,
        {},
        {
          ...headers,
          cancelToken: requestsSourceCancellation.token,
        }
      );
    },
      getProduct: (data, headers) => {
          return Api.get(
              `portal/distributor/distributor-brands/${data.id}/products/${data.supportBrandId}`,
              {},
              {
                  ...headers,
                  cancelToken: requestsSourceCancellation.token,
              }
          );
      },
      updateProduct: (data, headers) => {
          return Api.post(
              `portal/distributor/distributor-brands/${data.id}/products/${data.supportBrandId}`,
              {
                  price : data.data.price,
                  special_offer : data.data.special_offer,
                  on_demand : data.data.on_demand
              },
              {
                  ...headers,
                  cancelToken: requestsSourceCancellation.token,
              }
          );
      },
    add: (data, headers) =>
      Api.post(`portal/distributor/distributor-brands`, data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    update: (data, headers) => {
      return Api.put(
        `portal/distributor/distributor-brands/${data.id}`,
        data.body,
        {
          ...headers,
          cancelToken: requestsSourceCancellation.token,
        }
      );
    },
    delete: (data, headers) => {
      return Api.delete(
        `portal/distributor/distributor-brands/${data.id}`,
        {},
        {
          ...headers,
          cancelToken: requestsSourceCancellation.token,
        }
      );
    },
  },
  teams: {
    getAll: (data, headers) =>
      Api.get(
        `admin/profile`,
        {},
        {
          ...headers,
          cancelToken: requestsSourceCancellation.token,
        }
      ),
    getOne: (data, headers) => {
      return Api.get(
        `admin/profile/${data}`,
        {},
        {
          ...headers,
          cancelToken: requestsSourceCancellation.token,
        }
      );
    },
    post: (data, headers) => {
      if (data.type === "add") return endpoints.profile.add(data.body, headers);
      else return endpoints.media.changeMediaState(data.body);
    },
    add: (data, headers) =>
      Api.post(`admin/profile`, data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    update: (data, headers) => {
      return Api.patch(`admin/profile/${data.id}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      });
    },
  },
  divisions: {
    getAll: (data, headers) =>
      Api.get(
        `portal/divisions?page=${data.page}&limit=${data.limit}`,
        {},
        {
          ...headers,
          cancelToken: requestsSourceCancellation.token,
        }
      ),
    getOne: (data, headers) => {
      return Api.get(
        `portal/divisions/${data}`,
        {},
        {
          ...headers,
          cancelToken: requestsSourceCancellation.token,
        }
      );
    },
    add: (data, headers) => {
      return Api.post(`portal/divisions`, data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      });
    },
    update: (data, headers) => {
      return Api.patch(`portal/divisions/${data.id}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      });
    },
    delete: (data, headers) =>
      Api.delete(
        `portal/divisions/${data.id}`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
  },
  orders: {
    getAll: (data, headers) =>
      Api.get(
        `portal/${data.type}?page=${data.page}&perPage=${data.perPage}${data.queryFilter}&sorts=${data.sorts ?JSON.stringify(data.sorts) : ''}`,
        {},
        {
          ...headers,
          cancelToken: requestsSourceCancellation.token,
        }
      ),
    getDistributorTenders: (data, headers) =>
      Api.get(
        `portal/distributor/tenders?no_pagination=true`,
        {},
        {
          ...headers,
          cancelToken: requestsSourceCancellation.token,
        }
      ),
    changeStatus: (data, headers) => {
      return Api.post(
        `portal/distributor/change-order-status/${data.id}`,
        data.data,
        {
          ...headers,
          cancelToken: requestsSourceCancellation.token,
        }
      );
    },
    changeBundleStatus: (data, headers) => {
      return Api.post(
        `portal/distributor/change-bundle-order-status/${data.id}`,
        data.data,
        {
          ...headers,
          cancelToken: requestsSourceCancellation.token,
        }
      );
    },
  },
  requests: {
    received: {
      getAll: (data, headers) =>
        Api.get(
          `portal/received-requests-portal?no_pagination=true`,
          {},
          {
            ...headers,
            cancelToken: requestsSourceCancellation.token,
          }
        ),
      changeStatus: (data, headers) => {
        return Api.post(
          `portal/requests-portal/${data.id}/request-portal-status`,
          data.body,
          {
            ...headers,
            cancelToken: requestsSourceCancellation.token,
          }
        );
      },
      getOne: (data, headers) =>
        Api.get(
          `portal/requests-portal/${data}`,
          {},
          {
            ...headers,
            cancelToken: requestsSourceCancellation.token,
          }
        ),
    },
    sent: {
      getAll: (data, headers) =>
        Api.get(
          `portal/send-requests-portal`,
          {},
          {
            ...headers,
            cancelToken: requestsSourceCancellation.token,
          }
        ),
    },
  },
  distributors: {
    getAll: (data, headers) => {
      return Api.get(
        `portal/supplier/get-distributors`,
        {},
        {
          ...headers,
          cancelToken: requestsSourceCancellation.token,
        }
      );
    },
    getOne: (data, headers) => {
      return Api.get(
        `portal/supplier/get-distributors/${data}`,
        {},
        {
          ...headers,
          cancelToken: requestsSourceCancellation.token,
        }
      );
    },
    add: (data, headers) => {
      return Api.post(`portal/add-distributors`, data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      });
    },
    update: (data, headers) => {
      return Api.put(`portal/update-distributors/${data.id}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      });
    },
    delete: (data, headers) => {
      return Api.delete(
        `portal/remove-distributors/${data.id}`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      );
    },
  },
  products: {
    getAll: (data, headers) =>
      Api.get(
        `portal/all-product${data.queryParams}`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    addProductRequest: (data, headers) =>
      Api.post(`/portal/request-product`, data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
  },
  tenders: {
    available: {
      getAll: (data, headers) => {
        return Api.get(
          `portal/distributor/all-tenders${data.queryParams}`,
          {},
          {
            ...headers,
            cancelToken: requestsSourceCancellation.token,
          }
        );
      },
      getOne: (data, headers) => {
        return Api.get(
          `portal/distributor/tender/${data}`,
          {},
          {
            ...headers,
            cancelToken: requestsSourceCancellation.token,
          }
        );
      },
      send: (data, headers) =>
        Api.post(
          `portal/distributor/send-tender-quotation/${data.id}`,
          data.data,
          {
            ...headers,
            cancelToken: requestsSourceCancellation.token,
          }
        ),
    },
    quotations: {
      getAll: (data, headers) => {
        return Api.get(
          `portal/distributor/tender-quotations`,
          {},
          {
            ...headers,
            cancelToken: requestsSourceCancellation.token,
          }
        );
      },
      send: (data, headers) =>
        Api.post(`/distributor/send-tender-quotation/${data.id}`, data.data, {
          ...headers,
          cancelToken: requestsSourceCancellation.token,
        }),
    },
    orders: {
      getAll: (data, headers) => {
        return Api.get(
          `portal/distributor/tender-orders`,
          {},
          {
            ...headers,
            cancelToken: requestsSourceCancellation.token,
          }
        );
      },
      changeStatus: (data, headers) => {
        return Api.put(
          `portal/distributor/update-tender-order/${data.id}`,
          data.data,
          {
            ...headers,
            cancelToken: requestsSourceCancellation.token,
          }
        );
      },
    },
  },
  catalogs: {
    getAll: (data, headers) =>
      Api.get(
        `portal/catalogs`,
        {},
        {
          ...headers,
          cancelToken: requestsSourceCancellation.token,
        }
      ),
    getOne: (data, headers) => {
      return Api.get(
        `portal/catalogs/${data}`,
        {},
        {
          ...headers,
          cancelToken: requestsSourceCancellation.token,
        }
      );
    },
    add: (data, headers) =>
      Api.post(`portal/catalogs`, data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    removeProductCatalog: (data, headers) =>
      Api.post(
        `portal/catalogs/${data.id}/remove-single-product-catalog`,
        data.product_catalog,
        {
          ...headers,
          cancelToken: requestsSourceCancellation.token,
        }
      ),
    update: (data, headers) => {
      return Api.patch(`portal/catalogs/${data.id}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      });
    },
    delete: (data, headers) =>
      Api.delete(
        `portal/catalogs/${data.id}`,
        {},
        {
          ...headers,
          cancelToken: requestsSourceCancellation.token,
        }
      ),
  },
  productCatalogs: {
    getAll: (data, headers) =>
      Api.get(
        `portal/product-catalogs`,
        {},
        {
          ...headers,
          cancelToken: requestsSourceCancellation.token,
        }
      ),
    getOne: (data, headers) => {
      return Api.get(
        `portal/product-catalogs/${data.id}`,
        {},
        {
          ...headers,
          cancelToken: requestsSourceCancellation.token,
        }
      );
    },
    add: (data, headers) =>
      Api.post(`portal/product-catalogs`, data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    delete: (data, headers) =>
      Api.delete(
        `portal/product-catalogs/${data.id}`,
        {},
        {
          ...headers,
          cancelToken: requestsSourceCancellation.token,
        }
      ),
    update: (data, headers) => {
      return Api.put(`portal/product-catalogs/${data.id}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      });
    },
  },
  questions: {
    getAll: (data, headers) =>
      Api.get(
        `portal/distributor/questions?no_pagination=true`,
        {},
        {
          ...headers,
          cancelToken: requestsSourceCancellation.token,
        }
      ),
    getAllBySupplier: (data, headers) =>
      Api.get(
        `portal/supplier/questions?no_pagination=true`,
        {},
        {
          ...headers,
          cancelToken: requestsSourceCancellation.token,
        }
      ),
    getOne: (data, headers) => {
      return Api.get(
        `portal/distributor/questions/${data.id}`,
        {},
        {
          ...headers,
          cancelToken: requestsSourceCancellation.token,
        }
      );
    },
    getOneBySupplier: (data, headers) => {
      return Api.get(
        `portal/supplier/questions/${data.id}`,
        {},
        {
          ...headers,
          cancelToken: requestsSourceCancellation.token,
        }
      );
    },
    add: (data, headers) =>
      Api.post(`portal/distributor/questions`, data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    delete: (data, headers) =>
      Api.delete(
        `portal/distributor/questions/${data.id}`,
        {},
        {
          ...headers,
          cancelToken: requestsSourceCancellation.token,
        }
      ),
    deleteBySupplier: (data, headers) =>
      Api.delete(
        `portal/supplier/questions/${data.id}`,
        {},
        {
          ...headers,
          cancelToken: requestsSourceCancellation.token,
        }
      ),
    update: (data, headers) => {
      return Api.put(`portal/distributor/questions/${data.id}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      });
    },
  },
  answers: {
    getOne: (data, headers) => {
      return Api.get(
        `portal/answers/${data}`,
        {},
        {
          ...headers,
          cancelToken: requestsSourceCancellation.token,
        }
      );
    },
    add: (data, headers) =>
      Api.post(`portal/answers`, data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    delete: (data, headers) =>
      Api.delete(
        `portal/answers/${data}`,
        {},
        {
          ...headers,
          cancelToken: requestsSourceCancellation.token,
        }
      ),
    update: (data, headers) => {
      return Api.put(`portal/answers/${data.id}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      });
    },
  },
  GenericHandlers: {
    get: (data, headers) =>
      Api.get(data.uri || `portal/${data.type}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    getOne: (data, headers) =>
      Api.get(data.uri || `portal/${data.type}/${data.id}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    post: (data, headers) =>
      Api.post(data.uri || `portal/${data.type}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    delete: (data, headers) =>
      Api.delete(data.uri || `portal/${data.type}/${data.id}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    put: (data, headers) =>
      Api.put(data.uri || `portal/${data.type}/${data.id}`, data.editedData, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    patch: (data, headers) =>
      Api.patch(data.uri || `portal/${data.type}/${data.id}`, data.editedData, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
  },
};

export default endpoints;
