export const userHavePermission = (userPermission, ...permissions) => {
    return permissions.some(permission => {
        return userPermission.some(userPermission => userPermission === permission)
    })
}

export const getUserPermissions = (roles) => {
    let permissions = []
    for (const role of roles) {
        const rolesPermission = [];
        rolesPermission.push(role.name)
        permissions = [...permissions, ...rolesPermission];
    }
    return permissions;
}

export const getActivePermitedPath = (userPermission, data) => {
    if (typeof data.path === "object") {
        let path = '';
        data.path.map(activePath => {
            if (userHavePermission(userPermission, activePath)) {
                data.sections.map(section => {
                    if (typeof section.permission === "object" && section.permission.indexOf(activePath) !== -1) {
                        path = section.path;
                    }else if (section.permission === activePath)
                        path = section.path
                })
            }
        });
        return path;
    } else return data.path;
}