
import React from 'react';
import { Icon } from '@material-ui/core';
import { messages } from './data'
import useStyles from './styles';

function EmptyPage({ type }) {
    const classes = useStyles();

    return (
        <dev className={classes.root}>
            <Icon className={classes.Icon}> {messages[type].icon}</Icon> 
            <h2> {messages[type].message} </h2>
        </dev>
    );
}

EmptyPage.defaultProps = {
    type: 'notBuilt'
}
export default EmptyPage;
