/* eslint-disable import/no-anonymous-default-export */

export default {
    data: {
        'Profile': {
            icon: 'account_circle_outlined',
            EnglishLabel: 'Profile',
            ArabicLabel: 'Profile',
            permissions: ['distributor stats', 'supplier stats'],
            path: '/',
        },
        'Manage': {
            EnglishLabel: 'Manage',
            ArabicLabel: 'Manage',
            icon: 'settings',
            permissions: ['manage distributor inquiry', 'manage supplier inquiry', 'manage team', 'manage distributor', 'manage division', 'manage distributor order', 'manage supplier order', 'manage supplier request', 'manage distributor request', 'request product'],
            path: ['manage supplier inquiry', 'manage distributor team'],
            sections: [
                { title: 'Teams', permission: 'manage distributor team', icon: 'group', path: '/administration/manage/teams' },
                { title: 'Inquiries', permission: ['manage supplier inquiry', 'manage distributor inquiry'], icon: 'question_answer', path: '/administration/manage/inquiries' },
                { title: 'Requests', permission: ['manage supplier request', 'manage distributor request', 'manage normal request'], icon: 'import_export', path: '/administration/manage/requests' },
                { title: 'Orders', permission: ['manage distributor order', 'manage supplier order'], icon: 'shopping_basket', path: '/administration/manage/orders' },
                { title: 'Divisions', permission: 'manage supplier division', icon: 'account_tree', path: '/administration/manage/divisions' },
                { title: 'Distributors', icon: 'people_alt', permission: 'manage distributor', path: '/administration/manage/distributors' },
                { title: 'Products', icon: 'people_alt', permission: 'request product', path: '/administration/manage/products' },
            ],
        },
        'Shop': {
            permissions: ['manage distributor catalog'],
            EnglishLabel: 'Inventory',
            ArabicLabel: 'Inventory',
            icon: 'shop',
            path: '/administration/shop/product-catalog',
        },
        'Brands': {
            EnglishLabel: 'Supported Brands',
            ArabicLabel: 'Supported Brands',
            icon: 'book_mark',
            permissions: ['manage distributor brand'],
            path: '/administration/brands/supported-brands',
        },
        'Tenders': {
            EnglishLabel: 'Tenders',
            ArabicLabel: 'Tenders',
            icon: 'monetization_on',
            permissions: ['manage distributor tender'],
            path: '/administration/tenders/available-tenders',
            sections: [
                { title: 'Available', permission: 'manage distributor tender', icon: 'event_available', path: '/administration/tenders/available-tenders' },
                { title: 'Quotations', permission: 'manage distributor tender', icon: 'shopping_basket', path: '/administration/tenders/tender-quotations' },
                { title: 'Orders', permission: 'manage distributor tender', icon: 'shopping_cart', path: '/administration/tenders/tender-orders' }
            ],
        },
        'Questions': {
            EnglishLabel: 'Questions',
            ArabicLabel: 'Questions',
            icon: 'help_outline_outlined',
            permissions: ['manage distributor question', 'manage supplier question'],
            path: '/administration/questions',
        },
    },
};