import React, { Suspense, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { AuthRoute, PrivateRoute } from "../Components/CustomRoutes";
import Layout from "../Components/Layout";
import GeneralLoader from "~/Components/GeneralLoader";
import { StorageKeys } from "../Configs/Storage";
import AccountActions from "../Redux/Actions/Account";
import api from "~/Services/Api";
import { getStorage } from "~/Services/Helpers";
import { USER_INITIAL_STATE, useUserContext } from "~/Services/Providers";
import { authRoutes, privateRoutes, publicRoutes, } from "./RouteList";

export default function Routes() {
  const { setUserContext } = useUserContext();
  const dispatch = useDispatch();
  const [IsLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let token = getStorage(StorageKeys.token);
    let user = JSON.parse(window.localStorage.getItem(StorageKeys.userContext));

    async function fetchUser() {
      setIsLoading(true);
      if (!token || !user) {  
        dispatch(AccountActions.logout());
        setUserContext({ ...USER_INITIAL_STATE });
      } else {
        //TODO handle the fail response
        const res = await api.account.profile({}, { headers: { Authorization: token } });
        const data = !!res.content && res.ok ? res.content : user.data;
        const role = !!res.content && res.ok ? res.content : user.role;
        setUserContext({ isAuthenticated: true, token, data, role });
      }
      setIsLoading(false);
    }
    fetchUser();
  }, []);

  if (IsLoading) {
    return <GeneralLoader />;
  }

  return (
    <Suspense fallback={<GeneralLoader />}>
      <Switch>
        {authRoutes.map((RouteObj, index) => (
          <AuthRoute key={index} {...RouteObj} />
        ))}
        {publicRoutes.map((RouteObj, index) => (
          <Route key={index} {...RouteObj} />
        ))}
        <Layout>
          <Switch>
            {privateRoutes.map((RouteObj, index) => {
              return (
                <PrivateRoute key={index} {...RouteObj} />
              )
            })}
          </Switch>
        </Layout>
      </Switch>
    </Suspense>
  );
}
