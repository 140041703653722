import { Drawer, Hidden } from '@material-ui/core';
import classNames from 'classnames';
import React, { useState } from 'react';
import { MainMenu, SubMenu } from '~/Components/Menu';
import Navigator from '~/Routes/RouteNavigator';
import useStyles from './styles';


function Aside({ handleDrawerToggle, open }) {
    const classes = useStyles();
    const { data: navData, active: activeTile } = Navigator;
    const [activeNav, setActiveNav] = useState({ activeTile: '', activeItem: '' })

    const drawer = (
        <div>
            <div className={classes.toolbar} />
            <div style={{ display: 'flex' }}>
                <MainMenu
                    handleDrawerToggle={handleDrawerToggle}
                    setActive={setActiveNav}
                    data={navData}
                    activeNav={activeNav}
                />
                <SubMenu
                    data={!!activeNav.activeTile ? navData[activeNav.activeTile].sections : {}}
                    setActiveNav={setActiveNav}
                    activeNav={activeNav}
                />
            </div>
        </div>
    );

    return (
        <nav>
            <Hidden mdUp>
                <Drawer
                    variant="temporary"
                    anchor="left"
                    open={open}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    onClose={() => handleDrawerToggle(false)}
                >
                    {drawer}
                </Drawer>
            </Hidden>

            <Hidden smDown implementation="js">
                <Drawer
                    style={{ height: "100%" }}

                    variant="permanent"
                    classes={{
                        paper: classNames(classes.drawerPaper, !open && classes.drawerPaperClose),
                    }}
                    open={open}
                >

                    {drawer}
                </Drawer>
            </Hidden>
        </nav>
    )
}

export default Aside;
