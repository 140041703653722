import {
  AppBar,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Brightness6, Fullscreen, FullscreenExit } from "@material-ui/icons";
import MenuIcon from "@material-ui/icons/Menu";
import classNames from "classnames";
import React, { useState } from "react";
import { FullscreenHandler, toAbsoluteUrl } from "~/Services/Helpers";
import { useUserContext } from "~/Services/Providers";
import { types } from "../../../../Services/Helpers";
import MenuProfile from "../MenuProfile";
import useStyles from "./styles";
import OneSignalNotifications from "../../../OneSignalNotifications/index";
import SearchBar from "./Partials/Search";

const fullscreenElem = document.documentElement;
const ScreenHandler = new FullscreenHandler(fullscreenElem);
function Header({ handleDrawerToggle, drawerOpen }) {
  const classes = useStyles();
  const [FullScreen, setFullScreen] = useState(false);
  const { userContextObj } = useUserContext();
  const smScreen = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const type = userContextObj?.data?.type
    ? types[userContextObj.data.type - 1].name
    : "None";

  const toggleFullScreen = (e) => {
    FullScreen
      ? ScreenHandler.closeFullScreen()
      : ScreenHandler.openFullScreen();
    setFullScreen(!FullScreen);
  };
  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolBar}>
        <div style={{ display: "flex" }}>
          <div className={classes.menuItemIcon}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => handleDrawerToggle(!drawerOpen)}
              className={classNames(classes.menuButton)}
            >
              <MenuIcon />
            </IconButton>
          </div>
          <div className={classes.menuItemIcon}>
            <img className={classes.Logo} src={toAbsoluteUrl("/logo.png")} />
            <p className={classes.type}>{type} Portal</p>
          </div>
        </div>

        <div className={classes.menuLeft}>
          {smScreen && (
            <>
              <div className={classNames(classes.search, classes.menuItem)}>
                <SearchBar />
              </div>
              <div className={classes.menuItemIcon}>
                <Tooltip title={`${FullScreen ? "Close" : ""} Fullscreen`}>
                  <IconButton onClick={toggleFullScreen} aria-label="delete">
                    {FullScreen ? (
                      <FullscreenExit className={classes.menuIcon} />
                    ) : (
                      <Fullscreen className={classes.menuIcon} />
                    )}
                  </IconButton>
                </Tooltip>
              </div>

              {/* <div className={classes.menuItemIcon}>
                <Tooltip title="Turn on Dark/Light">
                  <IconButton aria-label="delete">
                    <Brightness6 className={classes.menuIcon} />
                  </IconButton>
                </Tooltip>
              </div> */}
              <div className={classes.menuItemIcon}>
                <Tooltip title="Notifications">
                  <OneSignalNotifications />
                </Tooltip>
              </div>
            </>
          )}

          <div className={classes.menuItem}>
            <MenuProfile />
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
