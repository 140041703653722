

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

    root: {
        '& .MuiIconButton-root': {
            color: 'rgba(255, 255, 255, 0.7)'
        }
    },
    dropdown: {
        '& .MuiMenu-paper': {
            width: '462px',
            position: "absolute",
            right: "108px",
            top: '18px'
        }


    },

    dropdownHeader: {
        display: "flex",
        justifyContent: 'space-between',
        padding: '1.5rem 1.5rem 0 1.5rem',
        fontSize: '1rem',
        alignItems: 'center',
        fontWeight: 600,
        marginBottom: '10px',
        color: theme.palette.type === "light" ? theme.palette.primary.main : "rgba(255, 255, 255, 0.7)",
    },
    seeAll: {

        fontSize: '10px',
        color: theme.palette.type === "light" ? 'blue' : '#9f9fef',
        fontWeight: '500',
        textTransform: 'capitalize',
        "&:hover": {
            backgroundColor: 'transparent',
        }
    },
    viewAll: {
        fontSize: '13px',
        color: theme.palette.type === "light" ? 'blue' : '#9f9fef',
        fontWeight: '500',
        textTransform: 'capitalize',
        "&:hover": {
            backgroundColor: 'transparent',
        }
    },
    iconButton: {
        minWidth: 'calc(100px * 0.5)',
        height: 'calc(100px * 0.5)',
        backgroundColor: theme.palette.primary.main,
        borderRadius: '50%',
        padding: '5px',
        margin: '7px',
        display: 'flex',
        alignItems: "center",
        justifyContent: "center",
        transition: "filter 300ms",
        '&:hover': {
            filter: 'brightness(1.2)',
        },
        '& .MuiSvgIcon-root': {
            fontSize: '2rem',
            color: '#c9fb36',
        }
    },
    iconRight: {
        marginRight: 'auto',
        marginTop: '3px',
        fontSize: '10px',
        color: theme.palette.type === "light" ? 'blue' : '#9f9fef',

    },
    loader: {
        display: "flex",
        justifyContent: "center",
        "& .MuiCircularProgress-root": {
            color: theme.palette.primary.main,
        }
    },
    menuItem: {
        justifyContent: 'space-between',
        textAlign: 'left',
        height: '82px',
        display: 'flex',
        alignItems: 'center',
        borderRadius: '3px',
        transition: 'background 500ms',
        cursor: 'pointer',
        padding: '15px 1.5rem',
        '& .icon-button ': {
            marginRight: "0.5rem",
        },
        '& .icon-button:hover': {
            filter: 'none',
        },
        '&:hover': {
            backgroundColor: theme.palette.type === "light" ? 'rgba(0, 0, 0, 0.04)' : 'rgba(255, 255, 255, 0.08);',
        },
        '&::after': {
            content: "''",
            width: '8px',
            height: '8px',
            MozBorderRadius: '7.5px',
            WebkitBorderRadius: "7.5px",
            borderRadius: '7.5px',
            backgroundColor: 'var(--my-color-var)',

        }

    },
    emptyIcon: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "20px"
    },
    footer: {
        padding: '10px',
        textAlign: 'center',
    },
    title: {
        fontWeight: '600',
        textTransform: 'capitalize',
        color: theme.palette.primary.main
    },
    messageContent: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        alignItems: 'flex-start',
        fontSize: '0.8rem',
        fontWeight: '500',
        lineHeight: '1.4',
        color: theme.palette.type === "light" ? '#212529ad' : "rgba(255, 255, 255, 0.7)",
        maxWidth: '96%',
    }
}));


export default useStyles;