import React, { useEffect, useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import useStyles from './style';
import { toAbsoluteUrl } from '~/Services/Helpers';
import { getAllNotification, makeAsRead } from '../api';
import { CircularProgress } from '@material-ui/core';
import { formatDistance } from 'date-fns';
import {useHistory} from "react-router-dom";

export default function NotificatinDrawer({ openDrower, setOpenDrower }) {
    const classes = useStyles();
    const [data, setData] = useState(false);
    const [loading, setLoading] = useState(false);
    const [serverDate, setServerDate] = useState('');
    const history = useHistory();


    const getAllNotifications = () => {
        setLoading(true);
        getAllNotification({ no_pagination: true }, (res) => {
            setLoading(false);
            setServerDate(res.data.date);
            setData(res.data.content);
        }, (error) => { })
    }

    const validURL = (str) =>  {
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
    }

    const handleItemClick = async (notfi) => {
        if (notfi.read != 1) {
            makeAsRead(notfi.id, (res) => {
                setOpenDrower(!openDrower);

                if (validURL(notfi.link)) {
                    window.open(notfi.link, '_blank');
                } else {
                    history.push(notfi.link)
                }
            })
        } else {
            setOpenDrower(!openDrower);

            if (validURL(notfi.link)) {
                window.open(notfi.link, '_blank');
            } else {
                history.push(notfi.link)
            }
        }

    }

    useEffect(() => {
        getAllNotifications();
    }, [])
    return (
        <Drawer
            className={classes.root}
            anchor={'right'}
            open={openDrower}
            onClose={() => setOpenDrower(false)}
        >
            <div className={classes.dropdownHeader}>
                <h2>
                    Notifications
                </h2>
                <div className={classes.seeAll}>
                    <img
                        src={toAbsoluteUrl('/favicon.png')}
                        width={50}
                        height={50}
                    />
                </div>
            </div>
            {loading ? <div className={classes.loader}>
                <CircularProgress />
            </div> :
                <div className={classes.content}>
                    {data.length > 0 && data.map((notfi) => (<div
                        onClick={() => handleItemClick(notfi)}
                        className={classes.menuItem}
                        style={{ '--my-color-var': notfi.read != 1 ? '#2e89ff' : "" }}
                    >
                        {notfi.leftIcon && <span className={classes.iconButton}>
                            {notfi.leftIcon}
                        </span>}
                        <div className={classes.messageContent}>
                            <div className={classes.title}>
                                {notfi.title}
                            </div>
                            <div>
                                {notfi.message}
                            </div>
                            <span className={classes.iconRight}>
                                {formatDistance(
                                    new Date(notfi.created_at),
                                    new Date(serverDate),
                                    { addSuffix: true }
                                )}
                            </span>
                        </div>
                    </div>))}
                </div>}
        </Drawer>
    );
}
